import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuDrawer from "./drawer";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { windowScroll } from "../utils/windowScroll";
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import ImageIcons from '../imageComponent/ImageIcons';
import { IoCall, IoCheckmarkDoneSharp, IoSearch } from "react-icons/io5";
import ScrollToTopButton from "../utils/bottomToTopScroll";
import { BsTwitterX } from "react-icons/bs";
import { FaPinterestP } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { IoLogoYoutube } from "react-icons/io";
import anganRealtyBookletPdf from '../../assets/images/angan_realty_booklet.pdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const Layout = ({ children }) => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();

  const isActive = (handle) => {
    return location.pathname === handle ? 'active' : '';
  }

  // const pathname = useLocation().pathname;

  const handleCopyText = () => {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = (data.mail);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    toast.success('Copied to Clipboard');
  };

  const [detail, setDetail] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/detail/getdetail`);
      console.log("response", response)
      setDetail(response?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  })

  return (
    <>
      <div className="py-[15px] bg-[#359dd0]">
        <div className="container">
          <div className="flex justify-between max-md:flex-wrap max-md:justify-center gap-[15px]">
            <ul className="flex items-center sm:gap-[30px] gap-[15px]">
              <li className="flex items-center gap-[5px]">
                <Link className="text-white hover:text-[#ffe2ae]" to="tel:+919988599485"><IoCall className="inline-block text-[22px]" /> +(91) {detail[0]?.number}</Link>
              </li>
              <li
                className="item cursor-pointer text-white duration-500 hover:text-[#ffe2ae] gap-[5px] flex items-center"
                onClick={handleCopyText}
              >
                <GrMail className="inline-block text-[22px]" />
                <div>Email Us</div>
                {/* <FaRegCopy /> */}
              </li>
            </ul>

            <div className="flex items-center gap-[20px] max-sm:w-full">
              <div className="relative rounded-[3px] max-md:w-full">
                <input
                  type="search"
                  placeholder="Search...."
                  className="h-[40px] pl-[12px] pr-[50px] focus:outline-none lg:w-[270px] max-md:w-full rounded-l-[2px]" />

                <button type="button" className="h-[40px] border-l border-[#359dd0] w-[40px] bg-[#fff] hover:bg-[#d2ac67] text-[#359dd0] hover:text-white absolute top-0 right-0 flex justify-center items-center duration-500">
                  <IoSearch className="text-[21px]" /></button>
              </div>
              <ul className="flex gap-[6px] max-lg:hidden">
                {socialMediaData.map((data, i) => (
                  <li key={i}>
                    <Link
                      to={data.url}
                      aria-label={data.aria}
                      role="button"
                      target="_blank"
                      className="bg-white text-[#359dd0] hover:text-[#fff] hover:bg-[#d2ac67] w-[35px] h-[35px] text-[15px] rounded-[50%] flex justify-center items-center"
                    >
                      <span>{data.icon}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header className="max-lg:py-[15px] bg-[#000] sticky shadow-[0px_1px_15px_#ffffff2b] top-0 left-0 w-full z-40">
        <div className="container">
          <div className="flex justify-end items-center lg:h-auto relative">
            <Link to="/" className="flex items-center py-[15px] lg:px-[30px] md:px-[25px] px-[20px] absolute left-0 top-0 max-lg:top-[-15px] bg-[#000] rounded-b-[20px] ">
              <img
                src={ImageIcons.logo}
                className="lg:max-h-[150px] sm:max-h-[110px] max-h-[100px]"
                alt='Logo'
              />
            </Link>

            <nav className='lg:block hidden '>
              <ul className=" flex items-center lg:flex-nowrap flex-wrap lg:justify-center justify-start justify-left">
                {data.navLinks.map((link, i) => (
                  <li key={i} className="relative group lg:w-auto w-full xl:ml-[50px] lg:ml-[40px] ml-[30px]">

                    <Link to={link.handle} onClick={windowScroll} className={`text-[#fff] hover:text-[#d2ac67] 2xl:text-[18px] font-medium xl:text-[17px] lg:inline-block block w-full lg:py-[35px] py-[10px] ${isActive(link.handle)}`}>{link.title}</Link>
                    {link.subLinks && (<div className="transform origin-[bottom_center] duration-500 scale-y-0 invisible opacity-0 visibil translate-z-100 group-hover:scale-y-100 group-hover:translate-z-0 group-hover:visible group-hover:opacity-100 content=[''] absolute bottom-0 left-[15px] border-transparent !border-b-white border-[15px]"></div>)}

                    {link.subLinks && (
                      <ul className="duration-500 origin-[top_center] overflow-hidden transform scale-y-0 invisible opacity-0 visibil translate-z-100 group-hover:scale-y-100 group-hover:translate-z-0 group-hover:visible group-hover:opacity-100 left-0 rounded-b-[10px] shadow-[0px_10px_15px_0px_rgba(0,0,0,0.2)] grid bg-white text-black md:absolute relative top-[100%] md:w-[250px] w-[100%] z-50 ">
                        {link.subLinks.map((item, i) => (
                          <li key={i} className="w-fill">
                            <Link to={item.handle} onClick={windowScroll} className={`border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#359dd0] hover:text-white px-[20px] py-[12px] `}>{item.title}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className="inline-block lg:hidden">
              <MenuDrawer navLinks={data.navLinks} />
            </div>
          </div>
        </div>
      </header >
      {children}
      < footer className="border-t-4 border-[#359dd0]">
        <div className="bg-[#000] text-white md:py-[70px] py-[50px] bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${ImageIcons.footerBg})` }}>
          <div className="container" >
            <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[30px]">
              <div>
                <Link to='/' className="mb-[15px] inline-block">
                  <img className="max-h-[130px] " src={ImageIcons.logo} alt="" />
                </Link>
                <p className="mb-0">Angan homes is a company which specialises in offering modern and comfortable apartments which are located in exquisite locations. We ensure an enjoyable living experience to our customers with our exceptional customer services.</p>
              </div>

              {footerLinks.map((link, i) => (
                <div key={i}>
                  <h3 className="t font-semibold pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#333]" >{link.title}</h3>
                  <ul className="grid gap-[8px]">
                    {link.subLinks.map((item, i) => (
                      <li key={i}>

                        <Link to={item.handle} className={`text-[16px] text-white hover:text-[#d2ac67] inline-flex items-center  ${isActive(item.handle)} `} onClick={windowScroll} ><IoCheckmarkDoneSharp className="mr-[6px] secondary_text" /> {item.link}</Link>

                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div>
                <h3 className=" font-semibold	pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#333]">Contact Info</h3>
                <div className="grid gap-[10px] md:gap-[15px]">
                  <div className="inline-flex gap-[10px] items-start">
                    <FaLocationDot className=" text-[30px] min-w-[30px]" />
                    <span className="text-[16px] ">{detail[0]?.address}</span>
                  </div>
                  <div>
                    <Link to={`tel:${detail[0]?.number}`} className="text-[16px]  hover:text-[#d2ac67] duration-500 inline-flex gap-[10px] items-center"><MdCall className="inline-block text-[30px] min-w-[30px]" />{detail[0]?.number}</Link>
                  </div>
                  <div>
                    <Link to={`mailto:${detail[0]?.mail}`} className="text-[16px] hover:text-[#d2ac67] duration-500 inline-flex gap-[10px] items-center">
                      <MdEmail className="inline-block text-[30px] min-w-[30px]" />{detail[0]?.mail}</Link>
                  </div>
                </div>
              </div>

              <div>
                <img className="max-w-[140px]" src={ImageIcons.locationScan} alt="" />

                <Link to={anganRealtyBookletPdf} target="_blank" onClick={windowScroll} class='mt-[20px] group relative inline-flex items-center justify-center overflow-hidden z-10 transition-all duration-500 ease-in-out px-[25px] py-[12px] text-[#fff] bg-[#359dd0] rounded-[6px] hover:text-[#fff]'>Download Our Brochure<span class='absolute bg-[#d2ac67] bottom-0 w-0 right-0 h-full transition-all ease-in-out duration-500 group-hover:w-[105%] -z-[1] group-focus:w-[105%] group-hover:right-auto group-hover:left-0'></span>
                </Link>

                <ul className="mt-[10px]">
                  {socialMediaData.map((data, i) => (
                    <li key={i} className="inline-block mr-[15px] pt-[15px] last:mr-0">
                      <Link
                        to={data.url}
                        aria-label={data.aria}
                        role="button"
                        target="_blank"
                        className='text-[18px] text-[#fff] hover:text-[#fff] w-[40px] h-[40px] rounded-full border border-[#fff] hover:border-[#d2ac67] hover:bg-[#d2ac67] flex justify-center items-center'
                      >
                        <span>{data.icon}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#fff] border-t border">
          <div className="container">
            <div className="flex md:justify-between justify-center items-center md:flex-nowrap flex-wrap py-[15px] gap-[10px]">
              <p className="text-[16px] mb-0 text-center">© Copyright {currentYear} by Angan Homes. {detail[0]?.copyRight}</p>

              <div className="md:text-left text-center md:w-auto w-full">
                <Link onClick={windowScroll} className={`lg:mx-[20px] mx-[10px] my-[8px] inline-block hover:text-[#b28f4f] ${isActive("/privacy-policy")}`} to="/privacy-policy" >Privacy Policy</Link>
                <Link onClick={windowScroll} className={`lg:mx-[20px] mx-[10px] my-[8px] inline-block hover:text-[#b28f4f] ${isActive("/terms-and-conditions")}`} to="/terms-and-conditions" >Terms & Conditions</Link>
                <Link onClick={windowScroll} className={`lg:mx-[20px] mx-[10px] my-[8px] inline-block hover:text-[#b28f4f] ${isActive("/refund-policy")}`} to="/refund-policy" >Refund Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer >

      <ToastContainer position="bottom-right" autoClose={1000} hideProgressBar={true} />

      <ScrollToTopButton />

    </>
  );
};

const socialMediaData = [
  {
    url: "https://www.facebook.com/Anganhomes3bhkflats/",
    icon: <FaFacebookF />,
  },
  {
    url: "https://x.com/AnganHomes",
    icon: <BsTwitterX />,
  },
  {
    url: "https://www.linkedin.com/company/angan-homes/",
    icon: <FaLinkedinIn />,
  },
  {
    url: "https://www.youtube.com/@AnganHomes",
    icon: <IoLogoYoutube />,
  },
  {
    url: "https://www.pinterest.com/anganhome/",
    icon: <FaPinterestP />,
  },
  {
    url: "https://www.instagram.com/anganhomes/",
    icon: <BsInstagram />,
  },
];

const data = {
  // mobileNo: "9988599485",
  // mail: "info@anganhomes.com",
  // address: "High Ground Road, (Opposite Apple Resort) Chandigarh-Patiala Highway, Zirakpur",
  navLinks: [
    // {
    //   title: "Home",
    //   handle: "/"
    // },
    {
      title: "Properties",
      handle: "/properties",
      subLinks: [
        {
          title: "3 BHK Flats",
          handle: "/3-bhk-flats"
        }
      ]
    },
    {
      title: "Layout",
      handle: "/design-and-layout"
    },
    {
      title: "About Us",
      handle: "/about-us"
    },
    {
      title: "Contact Us",
      handle: "/contact-us"
    },
  ]
}

const footerLinks = [
  {
    title: "Useful Links",
    subLinks: [
      // {
      //   link: "Home",
      //   handle: "/"
      // },

      {
        link: "About Us",
        handle: "/about-us"
      },
      {
        link: "Blog",
        handle: "/blog"
      },
      {
        link: "Properties",
        handle: "/properties"
      },
      {
        link: "Contact Us",
        handle: "/contact-us"
      },
      {
        link: "Gallery",
        handle: "/gallery"
      },
      {
        link: "Sitemap",
        handle: "/sitemap.html"
      },
      {
        link: "Feedback",
        handle: "/feedback"
      },
    ]
  }
];

export default Layout;
