import React, { useState, useEffect } from 'react';
import Layout from '../components/shared/layout';
import { PostRequest } from '../components/utils/request';
import stdCodes from "../components/utils/data/stdCodes.json";
import { Link } from 'react-router-dom';
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { TbMailOpened } from "react-icons/tb";
import { GrMap } from "react-icons/gr";
import { FaCheckCircle } from "react-icons/fa";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import { validateEmail } from '../components/utils/formFunctions';
import Loader from '../components/shared/loader';
import axios from 'axios';


const Contact = () => {
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState({
    name: false,
    email: false,
    phone: false,
    subject: false,
    msg: "",
    message: false,
    success: false
  });

  const [formInput, setFormInput] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    std: "+91",
    message: ""
  })

  const firstNameHandler = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Allow spaces

    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) => match.toUpperCase());

    if (capitalizedValue.length <= 30) {
      setFormInput((prevState) => ({ ...prevState, name: capitalizedValue }));
      setError((prevState) => ({ ...prevState, message: "" }));
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setFormInput((prevState) => ({ ...prevState, phone: numericValue }));
      setError((prevState) => ({ ...prevState, phone: false, message: '' }));
    }
  };


  const validRegex = /^(?!.*(\d)\1{9})[6-9]\d{9}$/;

  const submissionHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    setError((prevState) => ({ ...prevState, message: "" }));

    if (!formInput.name || !formInput.email || !formInput.phone || !formInput.subject || !formInput.message) {
      setError((prevState) => ({
        ...prevState, success: false, message: "Fields must not be empty"
      }));
      setDisabled(false);
    }
    else if (!validateEmail(formInput.email)) {
      setError((prevState) => ({
        ...prevState, email: true, message: "Email is invalid"
      }));
      setDisabled(false);
    }
    else if (!validRegex.test(formInput.phone)) {
      setError((prevState) => ({
        ...prevState, phone: true, message: "Phone Number must be valid"
      }));
      setDisabled(false);
    } else {
      PostRequest(`${process.env.REACT_APP_URL}/user/mail`, {
        name: formInput.name,
        email: formInput.email,
        phone: `${formInput.std} ${formInput.phone}`,
        message: formInput.message,
        subject: formInput.subject
      })

        .then((response) => {
          console.log(response)
          setError((prevState) => ({ ...prevState, success: true }));
          setTimeout(() => {
            setError((prevState) => ({ ...prevState, success: false }));
            setDisabled(false);
            setFormInput({
              name: "",
              email: "",
              phone: "",
              message: "",
              subject: "",
              std: "+91",
            });
          }, 3000);
        })

        .catch((error) => {
          console.error("Error:", error);
          setDisabled(false);
        });
    }
  }

  const [detail, setDetail] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/detail/getdetail`);
      console.log("response", response)
      setDetail(response?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  })

  return (
    <Layout>
      <InnerPageBanner title='Contact Us' maxWidth='472px' innerPageBanner={ImageIcons.contactUs} />

      <section className='bg-[#f5f8fd] md:pb-[180px]'>
        <div className="container">
          <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-3">
            <div className="group flex border-[2px] border-[#ededed] hover:border-[#fff] flex-col items-center gap-3 px-[15px] py-[40px] justify-center text-center duration-700 bg-black hover:bg-[#723f00] text-[#fff]">
              <TbMailOpened className='!text-[60px] md:!text-[70px] text-[#d2ac67] group-hover:text-[#fff] group-hover:scale-[1.08] duration-700' />
              <h5 className="text-[22px] md:text-[26px] font-bold mb-[5px] mt-0">Email</h5>
              <Link to={`mailto:${detail[0]?.mail}`} className="font-medium hover:text-[#fff] hover:underline">{detail[0]?.mail}</Link>
            </div>
            <div className="group flex border-[2px] border-[#ededed] hover:border-[#fff] flex-col items-center gap-3 px-[15px] py-[40px] justify-center text-center duration-700 bg-black hover:bg-[#723f00] text-[#fff]">
              <LiaPhoneVolumeSolid className='!text-[60px] md:!text-[70px] text-[#d2ac67] group-hover:text-[#fff] group-hover:scale-[1.08] duration-700' />
              <h5 className="text-[22px] md:text-[26px] font-bold mb-[5px] mt-0">Phone</h5>
              <Link to={`tel:${detail[0]?.number}`} className="font-medium hover:text-[#fff] hover:underline">+91 {detail[0]?.number}</Link>
            </div>
            <div className="group flex border-[2px] border-[#ededed] hover:border-[#fff] flex-col items-center gap-3 px-[15px] py-[40px] justify-center text-center duration-700 bg-black hover:bg-[#723f00] text-[#fff]">
              <GrMap size="60px" className='!text-[60px] md:!text-[70px] text-[#d2ac67] group-hover:text-[#fff] group-hover:scale-[1.08] duration-700' />
              <h5 className="text-[22px] md:text-[26px] font-bold mb-[5px] mt-0">Location</h5>
              <div className="font-medium">{detail[0]?.address}</div>
            </div>
          </div>
        </div>
      </section>

      <section className='md:pt-0 md:-mt-[100px]'>
        <div className='container !max-w-[1150px]'>
          {error.success ? (
            <div className="py-24 text-center ">
              <div className="text-center py-20">
                <h2>Thank You</h2>
                <p className="text-[20px] text-[#359dd0] font-bold">
                  We have received your message.
                </p>
                <FaCheckCircle
                  sx={{ fontSize: "100px", color: "#008000" }}
                  className="mx-auto text-5xl	"
                />
              </div>
              <p className="text-[16px] font-bold">
                You will be contacted soon by one of our team member.
              </p>
            </div>
          ) : (
            <>
              <div className="lg:p-[40px] md:p-[30px] p-0 md:shadow-[0_0_10px_#e5e5e5] bg-white relative">
                {disabled && (<Loader />)}
                <h3 className="text-[#071e43]  mb-[20px]">Send Us A Message</h3>
                <p className={`text-[14px] text-left text-[#FF0000] font-semibold mb-[10px] ${error.message ? 'block' : 'hidden'}`}>{error.message}</p>

                <div className="grid md:grid-cols-2 gap-[25px]" id="contactusForm">
                  <div>
                    <label className="inline-block text-[#071e43] font-medium mb-[10px]" htmlFor="name">Name <span className="text-[#d2ac67]">*</span></label>
                    <input name="name" id="name" value={formInput.name} onChange={(e) => firstNameHandler(e)} className="h-[55px] bg-white w-full focus:outline-none hover:border-[#359dd0] border-[#c4c4c4] px-[15px] border focus:border-[#d2ac67] rounded-[5px] focus:shadow-[0_0_2px_#d2ac67] text-[16px] " placeholder="Name" required={true} />
                  </div>

                  <div>
                    <label className="inline-block text-[#071e43] font-medium mb-[10px]" htmlFor="name">Enter Your Email <span className="text-[#d2ac67]">*</span></label>
                    <input name="email" id="email" disabled={disabled} value={formInput.email}
                      onChange={(e) => {
                        setFormInput(prevState => ({ ...prevState, email: e.target.value }));
                        setError(prevState => ({ ...prevState, email: false, message: "" }));
                      }}

                      className="h-[55px] bg-white w-full focus:outline-none hover:border-[#359dd0] border-[#c4c4c4] px-[15px] border focus:border-[#d2ac67] rounded-[5px] focus:shadow-[0_0_2px_#d2ac67] text-[16px]" placeholder="Enter Your Email" required={true}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div>
                    <label className="inline-block text-[#071e43] font-medium mb-[10px]" htmlFor="name">Enter Your Phone <span className="text-[#d2ac67]">*</span></label>
                    <div className="flex">
                      <select name="stdCode" id="stdCode" className="border-r-0 rounded-l-[5px] h-[55px] bg-white w-[80px] focus:outline-none hover:border-[#359dd0] border-[#c4c4c4] px-[15px] border focus:border-[#d2ac67] focus:shadow-[0_0_2px_#d2ac67] text-[16px]"
                        value={formInput.std}
                        onChange={(e) =>
                          setFormInput((prevState) => ({
                            ...prevState,
                            std: e.target.value,
                          }))
                        }>
                        {stdCodes.map((item, i) => (
                          <option
                            key={i} value={item.dial_code}

                          >
                            {item.dial_code} {item.code}
                          </option>
                        ))}
                      </select>
                      <input name="phone" id="phone" value={formInput.phone} disabled={disabled} onChange={(e) => handlePhoneInputChange(e)}
                        className="h-[55px] bg-white w-full focus:outline-none hover:border-[#359dd0] border-[#c4c4c4] px-[15px] border focus:border-[#d2ac67] rounded-[5px] rounded-l-none focus:shadow-[0_0_2px_#d2ac67] text-[16px]" placeholder="Enter Your Phone"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="inline-block text-[#071e43] font-medium mb-[10px]" htmlFor="name">Enter Your Subject <span className="text-[#d2ac67]">*</span></label>
                    <input

                      name="subject"
                      id="subject"
                      value={formInput.subject}
                      disabled={disabled}
                      onChange={(e) => {
                        setFormInput(prevState => ({ ...prevState, subject: e.target.value }));
                        setError(prevState => ({ ...prevState, subject: false, message: "" }));
                      }}
                      className="h-[55px] bg-white w-full focus:outline-none hover:border-[#359dd0] border-[#c4c4c4] px-[15px] border focus:border-[#d2ac67] rounded-[5px] focus:shadow-[0_0_2px_#d2ac67] text-[16px]"
                      placeholder="Enter Your Subject"
                    />
                  </div>

                  <div className="sm:col-span-2 col-span-1">
                    <label className="inline-block text-[#071e43] font-medium mb-[10px]" htmlFor="name">Enter Your Message <span className="text-[#d2ac67]">*</span></label>
                    <textarea
                      className="h-[50px] w-full focus:outline-none hover:border-[#359dd0] border-[#c4c4c4] px-[15px] border focus:border-[#d2ac67] rounded-[5px] focus:shadow-[0_0_2px_#d2ac67] py-[12px] text-[16px] min-h-[80px] resize-none false"
                      name="message"
                      id="message"
                      value={formInput.message}
                      disabled={disabled}
                      onChange={(e) => {
                        setFormInput(prevState => ({ ...prevState, message: e.target.value }));
                        setError(prevState => ({ ...prevState, msg: false, message: "" }));
                      }}
                      placeholder="Message"
                      rows="4"
                      cols="30"
                    ></textarea>
                  </div>

                  <div>
                    <button className="bg-[#359dd0] duration-500 text-white px-[40px] h-[55px] leading-[45px] inline-block rounded-[8px] hover:bg-[#d2ac67] uppercase font-medium" onClick={submissionHandler}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
 
      <div className='map'>
        <iframe
          title='map'
          width="100%"
          className='min-h-[100%] h-[350px] md:h-[500px] bg-[#e8eaed]'
          height=""
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.180033914094!2d76.69176177607471!3d30.713338786568798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef99cc17fe25%3A0x6a593a19f7208f9b!2sAbacus%20Cloud!5e0!3m2!1sen!2sus!4v1705467957769!5m2!1sen!2sus"
        ></iframe>
      </div>
    </Layout>
  )
}

const data = {
  email: "info@anganhomes.com",
  phone: "+91 9988599485",
  address: "High Ground Road, (Opposite Apple Resort) Chandigarh-Patiala Highway, Zirakpur"
}

export default Contact